<template>
  <div>
   <!--  <base-header>
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Facturas</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header> -->
    <div class="container-fluid mt-4">
      <div>
        <loading-overlay :active.sync="loading1" :is-full-page="true" />
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0 text-primary">Historial de facturas</h3>
              </div>
              <div class="col-6 text-right">
                <base-button type="primary" icon size="sm" @click="addBill()">
                  <span class="btn-inner-icon"
                    ><i class="fa fa-plus-circle pr-2"></i
                  ></span>
                  <span class="btn-inner-text">Factura</span>
                </base-button>
              </div>
            </div>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select p-2 "
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary "
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <el-select
                class="select-primary pagination-select p-2 "
                v-model="promociones.select"
                placeholder="Promociones"
              >
                <el-option
                  class="select-primary "
                  v-for="item in promociones.multiple"
                  :key="item.value"
                  :label="item.label"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div class="pt-2">
                <base-input
                  v-model="query"
                  type="search"
                  prepend-icon="fas fa-search"
                  placeholder="Buscar..."
                  clearable
                />
              </div>
            </div>
            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="facturas"
              @sort-change="sortChange"
            >
              <div class="vld-parent" slot="empty" v-if="loading">
                <loading-overlay :active.sync="loading" :is-full-page="false" />
                <img
                  src="/img/white_image.png"
                  style="height: 100px; width: 100px"
                />
              </div>
              <div slot="empty" v-if="facturas.length == 0 && !loading">
                <span>No se ha encontrado</span>
              </div>
              <el-table-column
                min-width="200px"
                label="No. factura"
                prop="bill_number"
                sortable="custom"
              />
              <el-table-column
                min-width="200px"
                label="Fecha emisión"
                prop="bill_date"
                sortable="custom"
              />
              <el-table-column
                min-width="220px"
                label="Nombre de la promocion"
                prop="promotion"
                sortable="custom"
              />
              <el-table-column
                min-width="200px"
                align="center"
                label="Link foto"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <a
                    type="text"
                    :href="row.link"
                    target="_blank"
                    class="table-action"
                    style="cursor: pointer"
                  >
                    {{row.link}}
                  </a>
                </div>
              </el-table-column>
              <!--<el-table-column
                min-width="200px"
                align="center"
                label="Acciones"
              >
                <div slot-scope="{ row }" class="table-actions">
                  <el-tooltip content="Eliminar" placement="top">
                    <a
                      type="text"
                      @click="askDelete(row)"
                      class="table-action table-action-delete"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="fas fa-trash" />
                    </a>
                  </el-tooltip>
                </div>
              </el-table-column>-->
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Motrando {{ total ? from + 1 : 0 }} a {{ to }} de
                {{ total }} entradas
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
/* import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb"; */
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import axios from 'axios'

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
   /*  RouteBreadCrumb, */
  },

  data() {
    return {
      facturas: [],

      query: null,

      sort: "-created_at",

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      promociones: {select: '', multiple: []},

      total: 0,
      loading: true,
      able: true,
      loading1: false,
      routing: true,
    };
  },
  created(){
    this.getPromotion();
  },
  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getHistory",
      immediate: false,
      deep: true,
    },
    promociones: {
      handler: "getHistory",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    getPromotion(){
      axios.get('https://api.clubdefacturas.com/api/v1/get-promotions', {headers: {  
      'Content-Type': 'application/json'
      }})
      .then((r) => {
          const promocion = r.data.result
          for(let i=0; i < promocion.length; i++){
            let NewPromotion = {"value": promocion[i].promotion_id, "label":promocion[i].name}
            this.promociones.multiple.push(NewPromotion)
          }
      })
      .catch((e) => {
          this.promociones.multiple = []
      })
    },
    askDelete(bill) {
      swal.fire({
          title: "¿Está seguro de eliminar la factura?",
          confirmButtonText: `Continuar`,
          denyButtonText: `Cancelar`,
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteBill(bill);
          }
        });
    },
    async deleteBill(bill) {
      this.loading1 = true;
      try {
        const fecha = new Date();
        bill.deleted_at = `${fecha.getFullYear()}-${fecha.getMonth() + 1}-${fecha.getDate()}T${fecha.getHours()}:${fecha.getMinutes()}:${fecha.getSeconds()}`;
        await this.$store.dispatch("bills/update", bill);
        this.$notify({
          message: "Factura eliminada exitosamente.",
        });
        this.loading1 = false;
        this.getHistory();
      } catch (err) {
        this.loading1 = false;
        this.$notify({
          type: "danger",
          message: "Lo sentimos, por favor inténtelo más tarde.",
        });
      }
    },
    async getHistory() {
      if (this.able) {
        this.loading = true;
        this.facturas = [];
        try {
          this.able = false;
          const params = {
            limit: this.pagination.perPage,
            page: this.pagination.currentPage,
            order: this.sort,
            search: this.query,
            promotion: this.promociones.select
          };
          await this.$store.dispatch("bills/getHistory", params);
          const response = this.$store.getters["bills/bill"];
          this.facturas = response.data;
          this.total = response.meta.page.total;
          this.loading = false;
          this.able = true;
        } catch (e) {
          this.loading = false;
          this.able = true;
          this.$notify({
            type: "danger",
            message: `Lo sentimos, inténtelo más tarde.`,
          });
        }
      }
    },
    getListDebounced: _.debounce(function () {
      this.getHistory();
    }, 300),
    addBill() {
      const path = `/bills/add`;
      if (this.$route.path !== path && this.routing) {
        this.routing = false;
        this.$router.push(path);
      }
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getHistory();
    },
  },
};
</script>
